import React, { createElement } from 'react'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import { Link } from 'gatsby'
import { map, take, pick } from 'lodash'
// import SwitchLocale from 'components/common/SwitchLocale'
import { withAuth } from 'components/common/Auth'
import { withLocale } from 'components/common/Locale'
import routes from '~/routes'
import style from './footer.module.scss'

const FooterItem = ({ component: Component, className, ...props }) => (
  <Component className={ cx(style.item, className) } {...props} />
)

const Footer = ({ isLoggedIn, user = {}, i18n, locale, globalConfiguration, articles }) => {
  const { phone, email } = globalConfiguration
  const socialMedia = pick(globalConfiguration, ['facebook', 'instagram', 'twitter', 'youtube'])

  return (
    <footer className={ style.main }>
      <div className={ cx(style.container, style.mainContainer) }>
        <div className={ cx(style.section, style.articlesSection) }>
          <h4><Trans>Enlaces de interés</Trans></h4>
          <div className={ cx(style.articles) }>
            {
              map(take([
                ...articles.public,
                ...(isLoggedIn ? articles.private : []),
                ...(user.type === 'professional' ? articles.professional : []),
              ], 8), ([url, title], index) =>
                <FooterItem
                  key={ url }
                  component={ Link }
                  to={ url }
                >{ title }</FooterItem>
              )
            }
          </div>
        </div>
        <div className={ style.section }>
          <h4><Trans>Contacto</Trans></h4>
          <FooterItem component="a" href={`tel:${ phone }`}>{ phone }</FooterItem>
          <FooterItem component="a" href={`mailto:${ email }`}>{ email }</FooterItem>
        </div>
        <div className={ style.section }>
          {
            /*<h4><Trans>Idioma</Trans></h4>
           <SwitchLocale /> */
          }
          <div className={ style.socialIcons }>
          {
            map(socialMedia, (href, name) =>
              <a key={ name } href={ href } className={ style.socialIcon }>
                {
                  createElement(require(`~/assets/images/icon/${ name }.svg`))
                }
              </a>
            )
          }
          </div>
        </div>
      </div>
      <div className={ cx(style.container, style.secondaryContainer) }>
        <span><Trans>Copyright © 2018 – Fundación Step by Step</Trans></span>
        <nav>
        {
          map({
            [routes.institutional({
              node_locale: locale,
              key: 'privacy-policy',
            })]: i18n._(t`Política de privacidad`),
            [routes.institutional({
              node_locale: locale,
              key: 'terms-and-conditions',
            })]: i18n._(t`Términos y condiciones`),
          }, (title, href) =>
            <FooterItem
              key={ href }
              component={ Link }
              to={ href }
              className={ style.secondaryItem }
            >{ title }</FooterItem>
          )
        }
        </nav>
      </div>
    </footer>
  )
}

export default withAuth(withLocale(Footer))

export const MinimalFooter = () => (
  <div className={ style.minimalMain } />
)