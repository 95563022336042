import React from 'react'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import { identity, map, take } from 'lodash'
import routes from '~/routes'
import Item from '../Item'
import Submenu from '../Submenu'
import IconButton from '../IconButton'
import Button from 'components/common/Button'
import { Indicator as NewMessagesIndicator } from 'components/common/NewMessages'
import { withLocale } from 'components/common/Locale'
import style from './menu.module.scss'

const icons = {
  about: require('~/assets/images/icon/about.svg'),
  chat: require('~/assets/images/icon/chat.svg'),
  close: require('~/assets/images/icon/close.svg'),
  home: require('~/assets/images/icon/home.svg'),
  team: require('~/assets/images/icon/team.svg'),
}

const MainItem = ({ icon: Icon, wrapIcon = identity, children, ...props }) =>
  <Item className={ style.mainItem } {...props}>
    { wrapIcon(<Icon />) }
    { children }
  </Item>

const Menu = ({ i18n, isLoggedIn, locale, user = {}, location, toggleExpanded, categories = {} }) => (
  <>
    <Item className={ style.toggle } onClick={ toggleExpanded }>
      <IconButton component={ icons.close } />
    </Item>
    <nav className={ style.main }>
      <div className={ style.container }>
        <div className={ style.section }>
          <MainItem to={ routes.home() } icon={ icons.home }>
            <Trans>Inicio</Trans>
          </MainItem>
          <MainItem
            to={
              routes.institutional({
                node_locale: locale,
                key: 'about',
              })
            }
            icon={ icons.about }
          >
            <Trans>Sobre Lemon</Trans>
          </MainItem>
          <MainItem
            to={
              routes.institutional({
                node_locale: locale,
                key: 'team',
              })
            }
            icon={ icons.team }
          >
            <Trans>Equipo</Trans>
          </MainItem>
          <MainItem
            to={ routes.chat() }
            icon={ icons.chat }
            wrapIcon={ icon =>
              <NewMessagesIndicator
                className={ style.indicator }
                visible={ location.pathname !== routes.chat() }
              >
              { icon }
              </NewMessagesIndicator>
            }
          >
            <Trans>Chats y llamadas</Trans>
          </MainItem>
        </div>
        <div className={ style.section }>
          <Submenu
            title={ i18n._(t`Contenidos públicos`) }
            items={ take(categories.public, 6) }
          />
        </div>
        <div className={ style.section }>
        {
          isLoggedIn ?
            <>
              <Submenu
                title={ i18n._(t`Contenidos privados`) }
                items={ take(categories.private, 4) }
              />
              {
                user.type === 'professional' ?
                  <Submenu
                    title={ i18n._(t`Contenidos profesionales`) }
                    items={ take(categories.professional, 4) }
                  /> :
                  null
              }
            </> :
            <>
              <p className={ style.join }>
                <Trans>
                  Únete e Lemon y accede a todos los contenidos y funcionalidades de la plataforma!
                </Trans>
              </p>
              <div className={ style.actions }>
                <Item className={ style.buttonContainer } to={ routes.register() }>
                  <Button className={ style.button } isPrimary>
                    <Trans>¡Únete a lemon!</Trans>
                  </Button>
                </Item>
                <Item className={ style.buttonContainer } to={ routes.login() }>
                  <Button className={ style.button } isSecondary>
                    <Trans>Accede</Trans>
                  </Button>
                </Item>
              </div>
            </>
        }
        </div>
      </div>
    </nav>
  </>
)

export default withLocale(Menu)