import React, { Component } from 'react'
import { debounce } from 'lodash'

const aboveViewportThreshold = 0

export const withViewportScrollAwareness = Wrapped =>
  class ViewportScrollAware extends Component {
    state = {
      lastScrollTop: 0,
    }

    componentDidMount() {
      this.scrollHandler = this.handleScroll.bind(this)
      this.listener = window.addEventListener('scroll', this.scrollHandler)
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.scrollHandler)
    }

    handleScroll = ev => {
      const nextScrollTop = window.pageYOffset || document.documentElement.scrollTop
      const { lastScrollTop } = this.state

      this.setState({
        lastScrollTop: nextScrollTop,
        isScrollingUp: lastScrollTop > nextScrollTop,
        isAboveViewport: this.getElementAboveViewport(),
      })
    }

    getElementAboveViewport = () => {
      const bottom = this.el ? this.el.getBoundingClientRect().bottom : 0
      return bottom + aboveViewportThreshold <= 0
    }

    render() {
      const { isAboveViewport, isScrollingUp, lastScrollTop } = this.state

      return (
        <Wrapped
          ref={ node => this.el = node }
          isAboveViewport= { isAboveViewport }
          hasScrolled={ lastScrollTop > 0 }
          isScrollingUp={ isScrollingUp }
          {...this.props}
        />
      )
    }
}
