import React, { useState } from 'react'
import cx from 'classnames'
import { Link, navigate } from 'gatsby'
import { Trans } from '@lingui/macro'
import { flowRight, noop } from 'lodash'
import { withLocale } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import routes from '~/routes'
import { initials } from '~/utils/misc'
import style from './usermenu.module.scss'
import CloseIcon from '~/assets/images/icon/close.svg'

const UserAvatar = ({ user, className }) => (
  <span className={ cx(style.avatar, className) }>
  {
    user.picture ?
      <img className={ style.avatarImage } src={ user.picture } alt={ user.firstName } /> :
      initials(user.firstName, user.lastName)
  }
  </span>
)

const UserMenu = ({ i18n, user = {}, logout }) => {
  const [isExpanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!isExpanded)

  return (
    <div
      className={ style.main }
      tabIndex={ 0 }
      onBlur={ isExpanded ? toggleExpanded : noop }
    >
      <span onMouseDown={ toggleExpanded }>
      {
        isExpanded ?
          <span className={ style.close }>
            <CloseIcon />
          </span> :
          <UserAvatar user={ user } />
      }
      </span>
      <div className={ style.menu } aria-expanded={ isExpanded }>
        <div className={ style.userInfo }>
          <UserAvatar className={ style.userInfoAvatar } user={ user } />
          <span className={ style.name }>{ user.firstName } { user.lastName }</span>
          <span className={ style.secondary }>{ user.city } { user.company }</span>
        </div>
        <div className={ style.menuItems }>
          <span className={ style.menuItem } onMouseDown={ () => navigate(routes.profile()) }>
            <Trans>Ver perfil</Trans>
          </span>
          <span className={ style.menuItem } onMouseDown={ logout }>
            <Trans>Cerrar sesion</Trans>
          </span>
        </div>
      </div>
    </div>
  )
}

export default flowRight(
  withAuth,
  withLocale
)(UserMenu)
