import React, { Fragment } from 'react'
import cx from 'classnames'
import { flowRight, get, groupBy, map, mapValues, reduce, uniq } from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import DefaultLayout from './Default'
import ProfileLayout from './Profile'
import ChatLayout from './Chat'
import { withLocale } from 'components/common/Locale'
import { filterLocalizedNodes } from '~/utils/content'
import { selectNodes, globalConfigurationNodesAsMap } from '~/utils/misc'
import routes from '~/routes'

const makeLink = type => item => ([ routes[type](item), item.title ])

const selectAndGroupArticles = filtered => {
  const grouped = groupBy(filtered, article => get(article, 'category.visibilityGroup.slug'))
  return mapValues(grouped, nodes => map(nodes, makeLink('article')))
}

const selectAndGroupCategories = filtered =>
  reduce(filtered, (accum, category) => {
    accum[category.visibilityGroup.slug] = [
      ...(accum[category.visibilityGroup.slug] || []),
      makeLink('category')(category)
    ]
    return accum
  }, {})

const wrapWithQuery = Wrapped =>
  props =>
    <StaticQuery
      query={graphql`
        query LayoutQuery {

          allContentfulCategory {
            edges {
              node {
                title
                slug
                node_locale
                visibilityGroup {
                  slug
                }
              }
            }
          }

          allContentfulArticle {
            edges {
              node {
                title
                slug
                node_locale
                category {
                  visibilityGroup {
                    slug
                  }
                }
              }
            }
          }

          allContentfulGlobalConfiguration {
            edges {
              node {
                key
                value
              }
            }
          }
        }`
      }
      render={ ({ allContentfulArticle, allContentfulCategory, allContentfulGlobalConfiguration }) =>
        <>
          <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400italic,500,600,700" rel="stylesheet" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"  />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"  />
            <link rel="manifest" href="/site.webmanifest" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          <Wrapped
            articles={
              selectAndGroupArticles(
                filterLocalizedNodes(
                  selectNodes(allContentfulArticle.edges),
                  props.locale
                )
              )
            }
            categories={
              selectAndGroupCategories(
                filterLocalizedNodes(
                  selectNodes(allContentfulCategory.edges),
                  props.locale
                )
              )
            }
            globalConfiguration={
              globalConfigurationNodesAsMap(allContentfulGlobalConfiguration.edges)
            }
            { ...props }
          />
        </>
      }
    />

const enhance = flowRight(
  withLocale,
  wrapWithQuery,
)

export const Default = enhance(DefaultLayout)
export const Profile = enhance(ProfileLayout)
export const Chat = enhance(ChatLayout)
