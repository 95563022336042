import React, { Fragment } from 'react'
import cx from 'classnames'
import Helmet from 'react-helmet'
import Header, { MinimalHeader } from '../Header'
import { MinimalFooter } from '../Footer'
import style from './profile.module.scss'
import Intercom from 'components/common/Intercom'

export default ({ articles, categories, hasHeader, location, children }) =>
  <>
    {
      hasHeader ?
        <Header isInitiallyTransparent { ...{ articles, categories, location } } /> :
        null
    }
    <main className={ cx(style.main, {
      [style.withHeaderMain]: hasHeader,
    }) }>
      <div className={ style.container }>
        {
          !hasHeader ?
            <MinimalHeader /> :
            null
        }
        { children }
      </div>
      <Intercom />
    </main>
    <MinimalFooter />
  </>
