import React, { useEffect, useState } from 'react'

export default ({ children }) => {
  const [windowHeight, setWindowHeight] = useState('100vh')

  const handleResize = () => setWindowHeight(window.innerHeight)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div style={{ height: windowHeight }}>
    { children }
    </div>
  )
}