import React from 'react'
import { map } from 'lodash'
import Item from '../Item'
import style from './submenu.module.scss'
import ArrowIcon from '~/assets/images/icon/arrow.svg'

export default ({ title, items = [] }) =>
  items.length ?
    <>
      <h4>{ title }</h4>
      {
        map(items, ([slug, title]) =>
          <Item className={ style.submenuItem } key={ slug } to={ slug }>
            <ArrowIcon />
            { title }
          </Item>
        )
      }
    </> :
    null