import React, { useState, forwardRef } from 'react'
import cx from 'classnames'
import { flowRight } from 'lodash'
import { withAuth } from 'components/common/Auth'
import { Link } from 'gatsby'
import Menu from './Menu'
import Bar from './Bar'
import { withViewportScrollAwareness } from 'components/common/ViewportScrollAware'
import style from './header.module.scss'
import LogoColor from '~/assets/images/logo-color.svg'
import LogoWhite from '~/assets/images/logo.svg'

const Header = forwardRef((props, ref) => {
  const [isExpanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!isExpanded)

  return (
    <header ref={ ref } className={ style.main } aria-expanded={ isExpanded }>
      <Menu {...props} toggleExpanded={ toggleExpanded } />
      <Bar {...props} toggleExpanded={ toggleExpanded } />
    </header>
  )
})

export default flowRight(
  withAuth,
  withViewportScrollAwareness,
)(Header)

export const MinimalHeader = ({ className, isWhiteLogo }) =>
  <header className={ className }>
    <Link to="/" className={ cx(style.minimalHeaderLogo, {
      [style.isWhiteLogo]: isWhiteLogo
    }) }>
    {
      isWhiteLogo ?
        <LogoWhite /> :
        <LogoColor />
    }
    </Link>
  </header>