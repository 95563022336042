import React, { Component } from 'react'
import Intercom from 'react-intercom'
import { withAuth } from '~/components/common/Auth'
import settings from '~/settings'

class IntercomWrapper extends Component {
  state = {
    hasMounted: false,
  }

  componentDidMount() {
    this.setState({ hasMounted: true })
  }

  render() {
    const { user = {}, isLoggedIn } = this.props
    const { hasMounted } = this.state

    return hasMounted ?
      <Intercom
        appID={ settings.intercom.appId }
        {...(
          isLoggedIn ? {
            user_id: user.userId,
            email: user.email,
            name: user.name,
          } : {})
        }
      /> :
      null
  }
}

export default withAuth(IntercomWrapper)