import React from 'react'
import cx from 'classnames'
import style from './iconbutton.module.scss'

export default ({ component: Component, isActive }) =>
  <span className={ cx(style.main, {
    [style.isActive]: isActive,
  }) }>
    <Component />
  </span>
