import React, { Fragment } from 'react'
import { Trans } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import Notification from 'components/common/Notification'
import cx from 'classnames'
import Helmet from 'react-helmet'
import Header from '../Header'
import { isIOS, isAndroid, isAndroidAndNotChrome, isIOSAndNotSafari } from '~/utils/browser'
import DivFullHeight from 'components/common/ViewportSize/DivFullHeight'
import style from './chat.module.scss'

export default ({ articles, categories, location, children }) => {
  const isUnsupportedBrowser = isAndroidAndNotChrome() || isIOSAndNotSafari()
  const preferredBrowser = isIOS() ? 'Safari' : isAndroid() ? 'Chrome' : ''

  return (
    <DivFullHeight>
      <main className={ style.main }>
        {
          isUnsupportedBrowser ?
            <Notification>
              <Trans>
                Algunas características como las videollamadas no están soportadas, de momento, en este navegador.
                Por favor, utilice {preferredBrowser} para una mejor experiencia.
              </Trans>
            </Notification> :
            null
        }
        <Header isInitiallyTransparent { ...{ articles, categories, location } } />
        <div className={ style.container }>
          { children }
        </div>
      </main>
    </DivFullHeight>
  )
}