import React from 'react'
import cx from 'classnames'
import Header from '../Header'
import Footer from '../Footer'
import style from './default.module.scss'
import Intercom from 'components/common/Intercom'

export default ({ isHomeLayout, children, articles, categories, globalConfiguration, location }) => (
  <>
    <Header {...{articles, categories, location}} />
    <main className={ cx(style.main, {
      [style.homeMain]: isHomeLayout,
    }) }>
      { children }
      <Intercom />
    </main>
    <Footer
      globalConfiguration={ globalConfiguration }
      articles={ articles }
    />
  </>
)
