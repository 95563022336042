import React from 'react'
import { t, Trans } from '@lingui/macro'
import { Link } from 'gatsby'
import cx from 'classnames'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import { Indicator as NewMessagesIndicator } from 'components/common/NewMessages'
import Item from '../Item'
import IconButton from '../IconButton'
import UserMenu from '../UserMenu'
import Button from 'components/common/Button'
import style from './bar.module.scss'

const icons = {
  menu: require('~/assets/images/icon/menu.svg'),
  home: require('~/assets/images/icon/home.svg'),
  public: require('~/assets/images/icon/public.svg'),
  private: require('~/assets/images/icon/private.svg'),
  professional: require('~/assets/images/icon/professional.svg'),
  chat: require('~/assets/images/icon/chat.svg'),
}

const Logo = require('~/assets/images/logo.svg')

const isPathActive = (path, location) => path === location.pathname

const BarIconItem = ({ icon, isActive, isToggle, ...props }) =>
  <Item
    { ...props }
    className={ cx(style.item, style.iconItem, {
      [style.toggleItem]: isToggle,
      [style.activeItem]: isActive,
    }) }>
    <IconButton
      isActive={ isActive }
      component={ icon }
    />
  </Item>

const Bar = ({
  hasScrolled,
  i18n,
  isInitiallyTransparent,
  isLoggedIn,
  locale,
  location,
  toggleExpanded,
  user = {},
}) =>
  <div className={ cx(style.main, {
      [style.transparentMain]: isInitiallyTransparent && !hasScrolled,
    }) }>
    <nav className={ style.nav }>
      <BarIconItem
        onClick={ toggleExpanded }
        title={ i18n._(t`Menú`) }
        icon={ icons.menu }
        isToggle
      />
      <BarIconItem
        to={ routes.home() }
        title={ i18n._(t`Home`) }
        icon={ icons.home }
        isActive={ isPathActive(routes.home(), location) }
      />
      <BarIconItem
        to={ routes.visibilityGroup({ slug: 'public', node_locale: locale }) }
        title={ i18n._(t`Contenidos públicos`) }
        icon={ icons.public }
        isActive={ isPathActive(routes.visibilityGroup({ slug: 'public', node_locale: locale }), location) }
      />
      {
        isLoggedIn ?
        <>
          <BarIconItem
            to={ routes.visibilityGroup({ slug: 'private', node_locale: locale }) }
            title={ i18n._(t`Contenidos privados`) }
            icon={ icons.private }
            isActive={ isPathActive(routes.visibilityGroup({ slug: 'private', node_locale: locale }), location) }
          />
          {
            user.type === 'professional' ?
              <BarIconItem
                to={ routes.visibilityGroup({ slug: 'professional', node_locale: locale }) }
                title={ i18n._(t`Contenidos profesionales`) }
                icon={ icons.professional }
                isActive={ isPathActive(routes.visibilityGroup({ slug: 'professional', node_locale: locale }), location) }
              /> :
              null
          }
          <NewMessagesIndicator
            visible={ !isPathActive(routes.chat(), location) }
          >
            <BarIconItem
              to={ routes.chat() }
              title={ i18n._(t`Chat & Vídeo`) }
              icon={ icons.chat }
              isActive={ isPathActive(routes.chat(), location) }
            />
          </NewMessagesIndicator>
        </> :
        null
      }
    </nav>
    <Link
      to={ routes.home() }
      className={ cx(style.logo, {
        [style.isEnlargedLogo]: isInitiallyTransparent && !hasScrolled,
      }) }>
      <Logo />
    </Link>
    <nav className={ cx(style.nav, style.secondaryNav) }>
    {
      isLoggedIn ?
        <Item>
          <UserMenu />
        </Item> :
        <>
          <Item className={ style.item } to={ routes.register() }>
            <Button className={ cx(style.buttonItem, style.joinButton) } isPrimary>
              <Trans>¡Únete <span>a lemon</span>!</Trans>
            </Button>
          </Item>
          <Item className={ cx(style.item, style.loginButtonItem) } to={ routes.login() }>
            <Button isSecondary className={ style.buttonItem }>
              <Trans>Accede</Trans>
            </Button>
          </Item>
        </>
    }
    </nav>
  </div>

export default withLocale(Bar)