import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import CloseIcon from '~/assets/images/icon/close.svg'
import style from './notification.module.scss'

export default ({ children }) => {
  const [isClosed, setIsClosed] = useState(false)
  return (
    <div className={ cx(style.main, {
      [style.isClosed]: isClosed,
    }) }>
      { children }
      <div className={ style.close } role="button" onClick={ () => setIsClosed(true) }>
        <CloseIcon />
      </div>
    </div>
  )
}