import { createElement } from 'react'
import cx from 'classnames'
import style from './button.module.scss'

const Button = ({ component = 'div', children, isDisabled, isPrimary, isSecondary, isSecondaryDark, isBlue, className, ...props }) =>
  createElement(component, {
    className: cx(style.button, {
      [style.isPrimary]: isPrimary,
      [style.isBlue]: isBlue,
      [style.isSecondary]: isSecondary,
      [style.isSecondaryDark]: isSecondaryDark,
    }, className),
    disabled: isDisabled,
    ...props,
  }, children)

export default Button
