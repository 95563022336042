import { get } from 'lodash'

const ua = () => typeof window !== undefined ? get(window, 'navigator.userAgent', '') : ''

export const isIOS = () => !!ua().match(/iPad/i) || !!ua().match(/iPhone/i)

export const isAndroid = () => !!ua().match(/Android/)

export const isIOSAndNotSafari = () => isIOS() && !!ua().match(/WebKit/i) && !!ua().match(/CriOS/i)

export const isAndroidAndNotChrome = () => isAndroid() && !ua().match(/Chrome\/([\d.]+)/)
