//import snarkdown from 'snarkdown'
import unified from 'unified'
import markdown from 'remark-parse'
import html from 'remark-html'
import externalLinks from 'remark-external-links'
import { identity, flowRight, kebabCase, map, take, words } from 'lodash'
import cheerio from 'cheerio'

const processor = unified()
  .use(markdown, {commonmark: true})
  .use(externalLinks, {target: '_blank', rel: ['nofollow']})
  .use(html)

const parseHtmlString = html =>
  cheerio.load(html, { xmlMode: true })

const headings = 'h1,h2,h3,h4,h5,h6'

const makeId = str => kebabCase(take(words(str), 5))

export const withHeadingIds = html => {
  const $ = parseHtmlString(html)
  $(headings).each((index, el) => {
    $(el).attr('id', makeId($(el).text()))
  })
  return $.html()
}

export const mapHeadingIds = html => {
  const $ = parseHtmlString(html)
  const pairs = []
  $(headings).each((index, el) => pairs.push([makeId($(el).text()), $(el).text()]))
  return pairs
}

const toHtml = md =>
  processor.stringify(processor.parse(md))

export const fromMarkdown = (content, { shouldAddHeadingIds } = {}) =>
  (shouldAddHeadingIds ? withHeadingIds : identity)(toHtml(content))

export const addBasePath = (ids, base) =>
  map(ids, ([ id, heading ]) => ([ `${ base }#${ id }`, heading ]))

export const removeMarkdown = (md) =>
  md.replace(/(__|\*|\#)/g, '')

export const removeMarkdownImages = (md) =>
  md.replace(/(?:!\[(.*?)\]\((.*?)\))/, '')

export const removeMarkdownLinks = (md) =>
  md.replace(/(\[(.*?)\]\()(.+?)(\))/g, '')